import React from 'react';
import HeaderLogo from './HeaderLogo';
import Search from './Search';
import '../css/header.scss';

const Header = (props) => {

    return(
        <header>
            <ul className="row m-0 PGBGprops">
                <li className="col-md-6">
                    <HeaderLogo />
                </li>
                <li className="col-md-6">
                    <Search pgData={props.pgData} search={props.search} setSearch={props.setSearch} 
                        searchChangeHandler={props.searchChangeHandler} handleSearch={props.handleSearch} />
                </li>
            </ul>
        </header>
    )
}

export default Header;