import React from 'react';
import '../css/searchresultsbanner.scss';

const Searchresultsbanner = (props) => {
    return(
        <section className="d-flex align-items-center justify-content-center backgroundBanner">
            <h5 className="pt-4 pb-4 text-center text-white">Search Results for '{props.search.toUpperCase()}'</h5>
        </section>
    );
}

export default Searchresultsbanner;