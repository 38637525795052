import React from 'react';
import { Route, HashRouter, Switch } from "react-router-dom";
// import productData from './PGCData.json';
import './css/App.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Home from './components/Home';
// import Download from './components/Download';
import Contact from './components/Contact';
import Products from './components/Products';
import ProductList from './components/ProductList';
import ProductDetails from './components/ProductDetails';
import PageNotFound from './components/PageNotFound';
import SearchResults from './components/SearchResults';
import Loader from './components/Loader';
import Thankyou from './components/Thankyou';
import Formerror from './components/Formerror';

const App = () => {
  const [menu, setMenu] = React.useState(
    {
      "Home": [],
      "Products": [
          {
              "title": "2 Circuit Track System",
              "category": "2C",
              "link": "/category2C"
          },
          {
              "title": "3 Circuit Track System",
              "category": "3C",
              "link": "/category3C"
          },
          {
              "title": "H-Profile Grid System",
              "category": "HC",
              "link": "/categoryHC"
          },
          {
              "title": "Adapters",
              "category": "A",
              "link": "/categoryA"
          }
      ],
      // "Download Center": [],
      "Contact Us": []
    }
  );

  const initdobj = () => JSON.parse(localStorage.getItem('dobj')) || '';
  const [dobj, setdobj] = React.useState(initdobj);
  const initdlist = () => JSON.parse(localStorage.getItem('dlist')) || '';
  const [dlist, setdlist] = React.useState(initdlist);
  const initdindex = () => localStorage.getItem('dindex') || '';
  const [dindex, setdindex] = React.useState(initdindex);
  const initdcat_title = () => localStorage.getItem('dcat_title') || '';
  const [dcat_title, setdcat_title] = React.useState(initdcat_title);
  const initpgData = () => JSON.parse(localStorage.getItem('pgData')) || null;
  const [pgData, setpgData] = React.useState(initpgData);
  const [search, setSearch] = React.useState('');
  const [searchobj, setSearchobj] = React.useState('');
  const[loading, setLoading] = React.useState(false);
  const[loaderror, setLoaderror] = React.useState(null);

  const stateHandler = (obj,list,cat) => {
    let x = list.map((i,v) => i.partno === obj.partno && v);
    let y = x.filter(idx => idx !== false);
    setdobj(obj);
    setdlist(list);
    setdindex(Number(y));
    setdcat_title(cat);
    localStorage.setItem('dobj', JSON.stringify(obj));
    localStorage.setItem('dlist', JSON.stringify(list));
    localStorage.setItem('dindex', Number(y));
    localStorage.setItem('dcat_title', cat);
  }

  const stateDetailsHandler = (prm_dobj, prm_dcat, prm_dselindex) => {
    setdobj(prm_dobj);
    setdcat_title(prm_dcat);
    setdindex(prm_dselindex);
    localStorage.setItem('dobj', JSON.stringify(prm_dobj));
    localStorage.setItem('dindex', Number(prm_dselindex));
    localStorage.setItem('dcat_title', prm_dcat);
  }

  const searchChangeHandler = (v) => {
      setSearch(v.target.value);
  }

  const handleSearch = () => {
    const dataTmp2C = pgData['2 Circuit Track System'].slice(1);
    const dataTmp3C = pgData['3 Circuit Track System'].slice(1);
    const dataTmpHC = pgData['H-Profile Grid System'].slice(1);
    const dataTmpA = pgData['Adapters'].slice(1);
    const allData = [...dataTmp2C, ...dataTmp3C, ...dataTmpHC, ...dataTmpA];
    const filteredDesc = allData.filter((value,index,array) => {
        const x = value.description.toLowerCase();
        const y = value.name.toLowerCase();
        const z = value.partno.toLowerCase();
        return x.includes(search.toLowerCase()) || y.includes(search.toLowerCase()) || z.includes(search.toLowerCase());
    });
    setSearchobj(filteredDesc);
    localStorage.setItem( 'searchobj', JSON.stringify(filteredDesc) );
    window.location = '#/searchresults';
    // console.log(search);
  }

  React.useEffect(() => {
    setLoading(true);

    const abortController = new AbortController();
    const signal = abortController.signal;

    fetch('./PGCData.json', { signal: signal })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        setpgData(data);
        setLoading(false);
        localStorage.setItem('dobj', JSON.stringify(dobj));
        localStorage.setItem('dlist', JSON.stringify(dlist));
        localStorage.setItem('dindex', dindex);
        localStorage.setItem('dcat_title', dcat_title);
        localStorage.setItem('pgData', JSON.stringify(data));
      })
      .catch(error => {
        setLoaderror(error);
        setLoading(false);
      });

      return function cleanup() {
        abortController.abort();
      }
  },[]);

  const productList = menu.Products.map((i,k) => 
    <Route key={i.category} path={i.link} render={(props) => 
      <ProductList {...props} cat={i.category} title={i.title} stateHandler={stateHandler} pgData={pgData} />} />
  )

  if (loaderror) return <p>{loaderror.message}</p>
  if(loading===true) return <Loader />

  return (
    <div className="App-header">
      <Header pgData={pgData} search={search} setSearch={setSearch} searchChangeHandler={searchChangeHandler} handleSearch={handleSearch} />
      <HashRouter>
          <Menu menulist={menu} />
          <div id="contentContainer">
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/products" component={Products} />
                {productList}
                <Route exact path="/product-details/:category" render={(props) => 
                  <ProductDetails {...props} 
                    dobj={dobj} 
                    dlist={dlist} 
                    dindex={dindex} 
                    dcat_title={dcat_title} 
                    stateDetailsHandler={stateDetailsHandler} 
                  />} 
                />
                {/* <Route exact path="/download" component={Download} /> */}
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/searchresults" render={(props) => 
                  <SearchResults {...props} search={search} stateHandler={stateHandler} setSearch={setSearch} />} />
                <Route exact path="/thankyou" component={Thankyou} />
                <Route exact path="/error" component={Formerror} />
                <Route component={PageNotFound} />
              </Switch>
          </div>
        </HashRouter>
      <Footer />
    </div>
  );
}

export default App;
