import React from 'react';
import { Carousel } from 'react-bootstrap';

export default function CarouselPGModal(props) {
    const carouselBanner = props.carouselArr.map( (img, z) => 
        <Carousel.Item key={z}>
            <img src={`pg-images/${img}`} alt={`Powergear ${props.altname}`} className="img-fluid" />
        </Carousel.Item>
    )

    return(
        <Carousel interval={null} 
                className="xlModalContainer" 
                indicators={props.carouselArr.length === 1 ? false : true} 
                controls={props.carouselArr.length === 1 ? false : true}
                activeIndex={props.activeindex}
                direction={props.direction} 
                onSelect={props.handleSelect}
        >
            {carouselBanner}
        </Carousel>
    )
}
