import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Thankyou = () => {

    return(
        <main class="m-0 pl-3 pr-3 pt-5 pb-5">
            <div class="m-auto text-center">
                <h2 class="mr-auto ml-auto pb-4">Thank You.</h2>
                <p>Thanks for your inquiry. One of our representatives will be in touch with you within the next two business days.
                </p>
                <p className="pt-4"><a href="/#/"><FontAwesomeIcon icon={faChevronLeft} /> Go Back Home</a></p>
            </div>
        </main>
    )
}

export default Thankyou;