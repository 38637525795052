import React from 'react';
import pdfIcon from '../images/pdflogo.png';
import Searchresultsbanner from '../components/Searchresultsbanner';
import { Button } from 'react-bootstrap';
import '../css/searchresults.scss';

const SearchResults = (searchprops) => {
    const resultData = JSON.parse(localStorage.getItem('searchobj'));
    const categoryData = JSON.parse(localStorage.getItem('pgData'));

    const searchDetailsHandler = (selitem, catt) => {
        const cattData = categoryData[catt].slice(1);
        searchprops.stateHandler(selitem, cattData, catt)
    }

    const searchResults = resultData.map((item, ky) => {
        let category_title = '';
        switch (item.category) {
            case '2C':
                category_title = '2 Circuit Track System';
                break;
            case '3C':
                category_title = '3 Circuit Track System';
                break;
            case 'HC':
                category_title = 'H-Profile Grid System';
                break;
            case 'A':
                category_title = 'Adapters';
                break;
            default:
        }

        const learnMore = () => {
            window.location = `#/product-details/category{item.category}`;
            searchDetailsHandler(item, category_title);
        }

        return(
        <tr key={ky}>
            <th scope="col" className="columnSpacer"></th>

            <th scope="row" className="text-center"><a href={`#/product-details/category${item.category}`} onClick={() => searchDetailsHandler(item, category_title)}>
                <img src={`pg-images/thumbnails/${item.image[0]}`} alt={`Powergear ${item.name}`} className="img-fluid" /></a></th>
            <td ><a href={`#/product-details/category${item.name}`} onClick={() => searchDetailsHandler(item, category_title)} >{item.name}</a></td>
            <td>{item.partno}</td>
            <td className="text-center"><a href={`pg-specs/${item.category}/${item.datasheet}`} target="_blank" rel="noopener noreferrer">
                <img src={pdfIcon} alt={`Powergear ${item.name} Datasheet`} className="pdfIcon" /></a></td>
            <td className="table_cat"><a href={`#/category${item.category}`}>{category_title}</a></td>
            <td className="table_learn"><Button onClick={learnMore} className="llcSearchListButton">Learn More</Button></td>

            <th scope="col" className="columnSpacer"></th>
        </tr>
        );
    })

    return(
        <>
        <section>
            <Searchresultsbanner search={searchprops.search} />
        </section>
        <main>
            { resultData.length === 0 ? <div className="w-100 text-center p-0"><h6 className="p-5 m-0">No results available</h6></div> :  
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="columnSpacer"></th>

                        <th scope="col" className="pl-4 align-middle text-center">Image</th>
                        <th scope="col" className="align-middle">Name</th>
                        <th scope="col" className="align-middle">Part No.</th>
                        <th scope="col" className="align-middle text-center">Data Sheet</th>
                        <th scope="col" className="align-middle table_cat">Category</th>
                        <th scope="col" className="align-middle text-center table_learn"></th>

                        <th scope="col" className="columnSpacer"></th>
                    </tr>
                </thead>
                <tbody id="searchDataContainer">
                    { searchResults }
                </tbody>
            </table>
            }
        </main>
        </>
    )
}

export default SearchResults;