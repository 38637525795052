import React from 'react';
import { Card } from 'react-bootstrap';
import Homebanner from './Homebanner';
import PGHomeImage from '../images/pg_JuicesProduce.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import '../css/home.scss';
import PGSizes from '../images/pg_sizes.svg';
import mountAllThread from '../images/mount_Allthread.svg';
import mountHChannel from '../images/mount_HChannelWall.svg';
import mountRecessed from '../images/mount_Recessed.svg';
import mountRigidRod from '../images/mount_RigidRod.svg';
import mountSurface from '../images/mount_Surface.svg';
import mountAircraft from '../images/mount_AirCraftCable.svg';

const Home = () => {
    return(
        <>
        <Homebanner />
        <main className="row align-items-start homeContent">
            <section className="col-md-6">
                <h3 className="pgWhatIs">WHAT IS POWERGEAR?</h3>
                <p>Powergear is a sleek 4-wire track system, created to be used in architectural lighting projects that encompass 
                    commercial applications, including retail, office spaces, hotel lobbies and more.
                </p>
                <div className="whatisPGIMG"></div>
                <p>Powergear offers a high quality 3 circuit (3-phase) lighting track system which is fully compatible with
                3-phase adapters of all major brands. The comprehensive range of innovative accessories – like the
                twisted L and T connectors – give the designer maximum freedom to create the ultimate track plan. The
                track can easily be shortened and the Ezclik mounting features make the track system quick and easy to
                install. After cutting the track it’s immediately ready to install and there is no need to adjust the
                wires.
                </p>
                <p>
                    To guarantee cost-effectiveness and flexibility, we make use of our own manufacturing facilities with
                    our products being produced under certified ISO9001 and ISO14000 regulations and ENEC, UL, TUV and GS
                    labels which are relevant for the European market.
                </p>
                <img src={PGHomeImage} alt="Powergear Homepage" />
            </section>
            <section className="col-md-6">
            <div className="">
                    <h5 className="pb-2 pgUnderline">FEATURES</h5>
                    <ul className="pt-2">
                        <li><FontAwesomeIcon icon={faCheckCircle} size="2x" className="faIconStyle" /> <div>Compatible with Global Trac* and other track connectors</div></li>
                        <li><FontAwesomeIcon icon={faCheckCircle} size="2x" className="faIconStyle" /> <div>Suspension points on track every 4ft</div></li>
                        <li><FontAwesomeIcon icon={faCheckCircle} size="2x" className="faIconStyle" /> <div>Optional Recessed Track Kit available for a seamless drywall finish</div></li>
                        <li><FontAwesomeIcon icon={faCheckCircle} size="2x" className="faIconStyle" /> <div>2 circuit system allows for perfect dimming</div></li>
                        <li><FontAwesomeIcon icon={faCheckCircle} size="2x" className="faIconStyle" /> <div>Durable system with rigid frame, holds 20lbs/ft</div></li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h5 className="pb-2 pgUnderline">SIZES</h5>
                    <img src={PGSizes} className="pgSizes" alt="Powergear Size Graph" />
                </div>
                <div className="mt-5 mountingOptions">
                    <h5 className="mb-3 pb-2 pgUnderline">MOUNTING OPTIONS</h5>
                    <ul>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountRigidRod} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">Rigid Rod Canopy</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountAllThread} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">All Thread</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountAircraft} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">Air Craft Cable</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountSurface} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">Surface</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountRecessed} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">Recessed Track Kit</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                        <li>
                        <Card className="homeCardSize">
                            <Card.Img variant="top" src={mountHChannel} />
                            <Card.Body className="text-center homeCardBody">
                                <Card.Title className="homeCardTitle">H-Channel Wall Bracket</Card.Title>
                            </Card.Body>
                        </Card>
                        </li>
                    </ul> 
                </div>
            </section>
        </main>
        </>
    );
}

export default Home;