import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import CarouselPG from './CarouselPG';
import CarouselPGModal from './CarouselPGModal';

function ModalVC(propsmodal) {
    return (
        <Modal
        {...propsmodal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >   
            <Modal.Body>
                <CarouselPGModal carouselArr = {propsmodal.carouselarray} 
                    setmodalshow={propsmodal.setmodalshow} altname = {propsmodal.altname}
                    handleSelect={propsmodal.handleSelect} activeindex = {propsmodal.activeindex} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={propsmodal.onHide} className="llcButton">Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ModalPG(props) {
    return (
        <div className="container">
            <CarouselPG carouselArr={props.carouselArray} setmodalshow={props.setModalShow} 
                altname={props.altName} handleSelect={props.handleSelect} activeindex={props.index} />
            <ModalVC
                show={props.modalShow}
                onHide={() => props.setModalShow(false)}
                carouselarray = {props.carouselArray} 
                setmodalshow={props.setModalShow}
                altname = {props.altName}
                handleSelect={props.handleSelect}
                activeindex = {props.index}
            />
        </div>
    );
  }

export default ModalPG;