import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import '../css/loader.scss';

const Loader = () => {
    return(
        <div className="loaderCenter">
            <div><FontAwesomeIcon className="fa-spin spinnerColor" icon={faCog} /><h4 className="spinnerColor">Loading...</h4></div>
        </div>
    )
}

export default Loader;