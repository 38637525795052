import React from 'react';
import '../css/footer.scss';

const Footer = () => {
    return(
        <footer>
            <p>Copyright &copy; 2019. Powergear North America. All Rights Reserved.</p>
        </footer>
    )
}

export default Footer;