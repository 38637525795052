import React from 'react';
import logo from '../images/logo_PGCblue.svg';
import flagCan from '../images/canada_flag.svg';
import flagUSA from '../images/usa_flag.png';

const HeaderLogo = () => {
    return (
        <div>
            <img src={flagCan} alt="Powergear Canada Flag" className="img-fluid" />
            <img src={flagUSA} alt="Powergear USA Flag" className="img-fluid" />
            <a href="#/"><img src={logo} alt="Powergear Logo" /></a>
        </div>
    );
}

export default HeaderLogo;