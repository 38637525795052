import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarouselPG(props) {
    const stylePointer = {
        cursor: 'pointer'
    }

    const carouselBanner = props.carouselArr.map( (img, z) => 
        <Carousel.Item key={z} style={stylePointer}>
            <img src={`pg-images/${img}`} alt={`Powergear ${props.altname}`} className="img-fluid" onClick={()=>props.setmodalshow(true)}  />
        </Carousel.Item>
    )

    return(
        <Carousel interval={null} 
                className="xlModalContainer" 
                indicators={props.carouselArr.length === 1 ? false : true} 
                controls={props.carouselArr.length === 1 ? false : true}
                direction={props.direction} 
                onSelect={props.handleSelect}
                activeIndex={props.activeindex}
        >
            {carouselBanner}
        </Carousel>
    )
}

export default CarouselPG;