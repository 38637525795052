import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Formerror = () => {

    return(
        <main class="m-0 pl-3 pr-3 pt-5 pb-5">
            <div class="m-auto text-center">
                <h2 class="mr-auto ml-auto pb-4">Error!</h2>
                <p class="pb-4">There was an error submitting your inquiry. Please go back and try again.</p>
                <p className="pt-2"><a href="/#/contact"><FontAwesomeIcon icon={faChevronLeft} /> Go Back</a></p>
            </div>
        </main>
    )
}

export default Formerror;