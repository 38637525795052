import React from 'react';
import '../css/productlist.scss';
import pdfIcon from '../images/pdflogo.png';
import { Breadcrumb, Card, Button, OverlayTrigger } from 'react-bootstrap';

const ProductList = (plprops) => {
    const theaddata = plprops.pgData[plprops.title][0];
    const tdata = plprops.pgData[plprops.title].slice(1);

    const listProducts = tdata.map((item, ky) => {
        const swatchList = item.models.map((col, int) => {
            let colorClass = '';
            switch (col.color) {
                case 'Anodized Black':
                    colorClass = 'bg-swatch-abk';
                    break;
                case 'Black':
                    colorClass = 'bg-swatch-black';
                    break;
                case 'Silver':
                    colorClass = 'bg-swatch-silver';
                    break;
                case 'Gray':
                    colorClass = 'bg-secondary';
                    break;
                case 'White':
                    colorClass = 'bg-white';
                    break;
                default:
                    return;
            }
            const renderTooltip = props => (
                <div
                    {...props}
                    style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.80)',
                    padding: '2px 10px',
                    color: 'white',
                    borderRadius: 3,
                    ...props.style,
                    }}
                >
                    {col.color}
                </div>
            );
            return (
                <OverlayTrigger
                    key={int}
                    placement="top-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <Card className="m-1">
                        <Card.Body className={`${colorClass} swatch_height_list`}></Card.Body>
                    </Card>
                </OverlayTrigger>
            )
        });

        const learnMore = () => {
            window.location = `#/product-details/category{item.category}`;
            plprops.stateHandler(item,tdata,theaddata.title);
        }

        return (
        <tr key={ky}>
            <th scope="col" className="columnSpacer"></th>
            <th scope="row" className="table_imgthumbWidth"><a href={`#/product-details/category${item.category}`} onClick={() => plprops.stateHandler(item,tdata,theaddata.title)}>
                <img src={`pg-images/thumbnails/${item.image[0]}`} alt={`Powergear ${item.name}`} className="img-fluid" /></a></th>
            <td className="table_partnoWidth table_part"><a href={`#/product-details/category${item.category}`} onClick={() => plprops.stateHandler(item,tdata,theaddata.title)} >{item.partno}</a></td>
            <td className="table_descriptionWidth"><a className="font-weight-bold" href={`#/product-details/category${item.category}`} onClick={() => plprops.stateHandler(item,tdata,theaddata.title)} >{item.name}</a><br/>{item.description}</td>
            <td className="table_colorsWidth table_col"><div className="d-flex justify-content-center flex-wrap">{swatchList}</div></td>
            <td className="table_datasheetWidth"><a href={item.datasheet ? `pg-specs/${item.category}/${item.datasheet}` : undefined} target="_blank" rel="noopener noreferrer" className={item.datasheet ? 'showMe' : 'hideMe'}>
                <img src={pdfIcon} alt={`Powergear ${item.name} Datasheet`} className="pdfIcon" /></a></td>
            <td className="table_learnmoreWidth table_learn"><Button onClick={learnMore} className="llcListButton">Learn More</Button></td>
            <th scope="col" className="columnSpacer"></th>
        </tr>
        )
    })

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return(
        <>
            <Breadcrumb className="ml-3">
                <Breadcrumb.Item href="#/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#/products">Products</Breadcrumb.Item>
                <Breadcrumb.Item active>{theaddata.title}</Breadcrumb.Item>
            </Breadcrumb>
            <section className="pgBannerTmpl">
                <ul className="row m-0">
                    <li className="col-md-6" id="featureImage"><img src={`pg-images/${theaddata.feature}`} alt={`Powergear ${theaddata.title}`} className="img-fluid" /></li>
                    <li className="col-md-6 listBackground">
                        <h3 id="featureTitle">{theaddata.title}</h3>
                        <p id="featureIntro" className="text-left" dangerouslySetInnerHTML={{__html: theaddata.intro}}></p>
                    </li>
                </ul>
            </section>
            <main>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="columnSpacer"></th>

                            <th scope="col" className="pl-4 align-middle">Image</th>
                            <th scope="col" className="align-middle table_part">Part No.</th>
                            <th scope="col" className="align-middle">Product</th>
                            <th scope="col" className="align-middle text-center table_col">Colors</th>
                            <th scope="col" className="align-middle text-center">Data Sheet</th>
                            <th scope="col" className="align-middle text-center table_learn"></th>

                            <th scope="col" className="columnSpacer"></th>
                        </tr>
                    </thead>
                    <tbody id="dataContainer">
                        { listProducts }
                    </tbody>
                </table>
            </main>
        </>
    )
}

export default ProductList;