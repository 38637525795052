import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = (searchprops) => {
    return (
        <Form className="d-flex align-items-center justify-content-center" onSubmit={searchprops.search.length > 1 ? searchprops.handleSearch : undefined}>
            <Form.Group controlId="formSearch" className="mt-0 ml-0 mb-0 mr-1">
                <Form.Control type="text" placeholder="Search..." value={searchprops.search} onChange={searchprops.searchChangeHandler} />
            </Form.Group>
            <Button className="searchButton" type="submit">
                <FontAwesomeIcon icon={faSearch} size="xs" />
            </Button>
        </Form>
    )
}

export default Search;