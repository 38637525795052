import React from 'react';
import Banner from '../images/crest_OTRT.png';
import '../css/homebanner.scss';

const Homebanner = () => {
    return(
        <section className="pgBanner">
            <img src={Banner} alt="Powergear On The Right Track" />
        </section>
    );
}

export default Homebanner;