import React from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import pdfIcon from '../images/pdflogo.png';
import '../css/productdetails.scss';
import ModalPG from './ModalPG';

const ProductDetails = (pdprops) => {
    const dobj = JSON.parse(localStorage.getItem('dobj'));
    const dlist = JSON.parse(localStorage.getItem('dlist'));
    const dindex = JSON.parse(localStorage.getItem('dindex'));
    const dcat_title = localStorage.getItem('dcat_title')

    const selectedObject = dobj;
    const selectedCategory = dlist;
    const categoryLength = dlist.length;
    const selectedItem = dobj.partno;
    const selectedIDX = dindex;
    const categoryTitle= dcat_title;
    const carouselArray = dobj.image;
    const colorsArray = dobj.models;

    const selectHandler = (e) => {
        // console.log(e.target.value);
        const catlist = JSON.parse(localStorage.getItem('dlist'));
        let x = catlist.map(i => i.partno === e.target.value && i);
        let xi = catlist.map((i,v) => i.partno === e.target.value && v);
        let val = x.filter(idx => idx !== false);
        let valxi = xi.filter(idx => idx !== false);
        const params_dobj = val[0];
        const params_dcat = pdprops.dcat_title;
        const params_dselindex = Number(valxi[0]);

        pdprops.stateDetailsHandler(params_dobj, params_dcat, params_dselindex);
    }

    const prevItem = (p) => {
        const currIndex = JSON.parse(localStorage.getItem('dindex'));
        const prevIndex = Number(currIndex - 1);
        const cat = pdprops.dlist;
        let x = cat.map(i => i.partno === cat[prevIndex].partno && i);
        let val = x.filter(idx => idx !== false);
        const params_dobj = val[0];
        const params_dcat = pdprops.dcat_title;
        const params_dselindex = prevIndex;

        pdprops.stateDetailsHandler(params_dobj, params_dcat, params_dselindex);
        setIndex(0);
    }

    const nextItem = (n) => {
        const currIndex = JSON.parse(localStorage.getItem('dindex'));
        const nextIndex = Number(currIndex + 1);
        const cat = pdprops.dlist;
        let x = cat.map(i => i.partno === cat[nextIndex].partno && i);
        let val = x.filter(idx => idx !== false);
        const params_dobj = val[0];
        const params_dcat = pdprops.dcat_title;
        const params_dselindex = nextIndex;

        pdprops.stateDetailsHandler(params_dobj, params_dcat, params_dselindex);
        setIndex(0);
    }

    const swatchList = colorsArray.map((col, int) => {
        let colorClass = '';
        switch (col.color) {
            case 'Anodized Black':
                colorClass = 'bg-swatch-abk';
                break;
            case 'Black':
                colorClass = 'bg-swatch-black';
                break;
            case 'Silver':
                colorClass = 'bg-swatch-silver';
                break;
            case 'Gray':
                colorClass = 'bg-secondary';
                break;
            case 'White':
                colorClass = 'bg-white';
                break;
            default:
            }
            return (
                col.color ? (
                <Card className="cardSize" key={col.model}>
                    <div className={`${colorClass} swatch_height`}></div>
                    <Card.Body className="text-center p-1">
                        <Card.Text className="m-0">{col.color}</Card.Text>
                        <Card.Text className="m-0">{col.model}</Card.Text>
                    </Card.Body>
                </Card>
                ) : 
                (
                <p>{col.model}</p>
                )
            )
        })
        
    const selectList = selectedCategory.map((obj, i) => 
        <option key={i} category={obj.category} partnum={obj.partno}>{obj.partno}</option>
    );

    const [modalShow, setModalShow] = React.useState(false);
    const [index, setIndex] = React.useState(0);
    const [direction, setDirection] = React.useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setDirection(e.direction);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="detailspageContainer">
            <Breadcrumb className="pl-2">
                <Breadcrumb.Item href="#/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#/products">Products</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/category${selectedCategory[0].category}`}>{categoryTitle}</Breadcrumb.Item>
                <Breadcrumb.Item active>{selectedItem}</Breadcrumb.Item>
            </Breadcrumb>

            <main className="row detailspage">
                <section className="col-md-6 d-flex align-items-center">
                    <ModalPG carouselArray = {carouselArray} altName = {selectedObject.name} 
                        handleSelect={handleSelect} 
                        modalShow={modalShow} setModalShow={setModalShow} 
                        index={index} setIndex={setIndex} 
                        direction={direction} setDirection={setDirection} />
                </section>
                <section className="col-md-6">
                        <div className="detailsHeading text-center">
                            <h3 id="prodName" className="mt-3">{selectedObject.name}</h3>
                            <p id="prodDesc" dangerouslySetInnerHTML={{__html: selectedObject.description}}></p>
                        </div>

                        <hr className="m-0" />

                        <div className="p-0 m-0">
                            <ul className="detailsContainer">
                                <li>
                                    <ul className="llDatasheet">
                                        <li><a href={selectedObject.datasheet ? `pg-specs/${selectedObject.category}/${selectedObject.datasheet}` : undefined} target="_blank" rel="noopener noreferrer">
                                            <img src={pdfIcon} alt={`Powergear ${selectedObject.partno} Datasheet`} className={selectedObject.datasheet ? 'p-0' : 'p-0 disableIcon'} /></a></li>
                                        <li>{selectedObject.partno} Spec Sheet</li>
                                    </ul>
                                </li>
                                <li className="llSwatches text-center">
                                    <h6 className={selectedObject.models[0].color ? 'showMe' : 'hideMe'}><span>AVAILABLE COLOURS:</span></h6>
                                    <h6 className={!selectedObject.models[0].color ? 'showMe' : 'hideMe'}><span>PART NUMBER:</span></h6>
                                    <div className="d-flex flex-wrap justify-content-center align-items-center">{ swatchList }</div>
                                </li>
                                
                                <li>
                                    <div>
                                        <a className={(selectedIDX) === 0 ? "col-form-label disableLinks" : "col-form-label enableLinks"} 
                                            onClick={(selectedIDX) > 0 ? prevItem : undefined}><FontAwesomeIcon icon={faChevronLeft} /> Prev Item</a>

                                        <select id="prodSel" className="form-control form-control-sm w-auto" value={selectedItem} 
                                            onChange={selectHandler}>
                                            { selectList }
                                        </select>

                                        <a className={(selectedIDX)===(categoryLength-1) ? "col-form-label disableLinks" : "col-form-label enableLinks"} 
                                            onClick={(selectedIDX)!==(categoryLength-1) ? nextItem : undefined}>Next Item <FontAwesomeIcon icon={faChevronRight} /></a>
                                    </div>
                                </li>
                            </ul>    
                        </div>
                </section>
            </main>
        </div>
    )
}

export default React.memo(ProductDetails);