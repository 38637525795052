import React from 'react';
import '../css/pagenotfound.scss';

const PageNotFound = () => {
    return (
        <div className="page404">
            <h1>The requested page was not found!</h1>
        </div>
    )
}

export default PageNotFound;