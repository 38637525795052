import React from 'react';
import { Card, Button } from 'react-bootstrap';
import '../css/products.scss';

const Products = () => {
    const learnMore = url => {
        const path = `/#/category${url}`;
        window.location = path;
    }

    return(
        <div className="cardCatProducts">

            <Card className="cardWidth text-center">
            <Card.Img variant="top" src="/pg-images/2_circuit_track.jpg" onClick={() => learnMore('2C')} className="linkPointer" />
            <Card.Body className="text-center">
                <Card.Title>2 Circuit Track System</Card.Title>
                <Card.Text className="txtJustify">
                    Powergear® 2-phase track is a high quality track system for the professional lighting market. 
                    The track is suitable for track lights with 3-phase adaptors of all major brands.
                </Card.Text>
                <Button className="productsButton" onClick={() => learnMore('2C')}>LEARN MORE</Button>
            </Card.Body>
            </Card>

            <Card className="cardWidth text-center">
            <Card.Img variant="top" src="/pg-images/3_circuit_track.jpg" onClick={() => learnMore('3C')} className="linkPointer" />
            <Card.Body className="text-center">
                <Card.Title>3 Circuit Track System</Card.Title>
                <Card.Text className="txtJustify">
                    Powergear® 3-phase track is a high quality track system for the professional lighting market. 
                    The track is suitable for track lights with 3-phase adaptors of all major brands.
                </Card.Text>
                <Button className="productsButton" onClick={() => learnMore('3C')}>LEARN MORE</Button>
            </Card.Body>
            </Card>

            <Card className="cardWidth text-center">
            <Card.Img variant="top" src="/pg-images/H-Channelgrid.jpg" onClick={() => learnMore('HC')} className="linkPointer" />
            <Card.Body className="text-center">
                <Card.Title>H-Profile Grid System</Card.Title>
                <Card.Text className="txtJustify">
                    Powergear® H-Channel track is a high quality track system for the professional lighting market. 
                    The track is suitable for track lights with 3-phase adaptors of all major brands.
                </Card.Text>
                <Button className="productsButton" onClick={() => learnMore('HC')}>LEARN MORE</Button>
            </Card.Body>
            </Card>

            <Card className="cardWidth text-center">
            <Card.Img variant="top" src="/pg-images/Adapters.jpg" onClick={() => learnMore('A')} className="linkPointer" />
            <Card.Body className="text-center">
                <Card.Title>Adapters</Card.Title>
                <Card.Text className="txtJustify">
                    Powergear® Adapters is a high quality track system for the professional lighting market. 
                    The track is suitable for track lights with 3-phase adaptors of all major brands.
                </Card.Text>
                <Button className="productsButton" onClick={() => learnMore('A')}>LEARN MORE</Button>
            </Card.Body>
            </Card>

        </div>
    )
}

export default React.memo(Products);