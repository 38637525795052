import React from 'react';
import { Form, Button } from 'react-bootstrap';
import ContactImage from '../images/pg_ContactBG.jpg';
import '../css/contact.scss';

const Contact = () => {
    const [validated, setValidated] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [captcha, setCaptcha] = React.useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            console.log('this will show up if form is valid')
        }
        setValidated(true);
    };

    const handleReset = () => {
        setName('');
        setEmail('');
        setPhone('');
        setComments('');
        setCaptcha('');
        setValidated(false);
    }

    return(
        <main className="formContainer m-auto">
            <section>
                <img src={ContactImage} alt="Contact Powergear" className="conImg" />
                <p>PowerGear North America<br/>
                    90 West Beaver Creek Rd<br/>
                    Richmond Hill, ON L4B 1E7<br/>
                    <a href="mailto:info@powergeartrack.com">info@powergeartrack.com</a>
                </p>
            </section>
            <section className="w-100">
                <Form noValidate validated={validated} onSubmit={handleSubmit} method="POST" action="https://www.netfirms.com/scripts/formemail.html">
                    <input type="hidden" name="my_email" value="pflor@liteline.com" />
                    <input type="hidden" name="subject" value="Powergear - Website Question/Comment" />
                    <input type="hidden" name="thankyou_url" value="http://powergeartrack.com/#/thankyou" />
                    <input type="hidden" name="error_url" value="http://powergeartrack.com/#/error" />
                    <input type="hidden" name="order" value="Name,Email,Phone,Comments" />
                    <input type="hidden" name="replyto" value="pflor@liteline.com" />

                    <h3>Contact Us</h3>
                    <Form.Group className="mt-4">
                        <Form.Label className="control-label">Name:</Form.Label>
                        <Form.Control type="text" required name="Name" value={name} onChange={n => setName(n.target.value)}  />
                        <Form.Control.Feedback type="invalid">Please enter your Name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="control-label">Email:</Form.Label>
                        <Form.Control type="email" required name="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={email} 
                            onChange={e => setEmail(e.target.value)}  />
                        <Form.Control.Feedback type="invalid">Please enter a valid E-mail address.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="text" name="Phone" value={phone} onChange={p => setPhone(p.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="control-label">Comments:</Form.Label>
                        <Form.Control name="Comments" as="textarea" rows="4" required value={comments} onChange={c => setComments(c.target.value)} />
                        <Form.Control.Feedback type="invalid">This field cannot be empty.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check required type="checkbox" id="invalidCheck" label="I consent to receiving marketing information about Powergear" 
                            feedback="You must agree before submitting." checked={captcha} onChange={capt => setCaptcha(capt.target.checked)} />
                    </Form.Group>
                    <Button id="pgcSubmit" type="submit" className="btnSpacing contactButton">Submit</Button>
                    <Button id="pgcReset" className="btnSpacing contactButton" onClick={handleReset}>Reset</Button>
                </Form>
            </section>
        </main>
    )
}

export default Contact;