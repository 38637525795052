import React from 'react';
import '../css/menu.scss';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'

const Menu = (menuprops) => {      
        return (
            <Navbar id="navbarId" bg="light" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto justify-content-center d-flex flex-fill">

                    <NavLink to="/">{Object.keys(menuprops.menulist)[0]}</NavLink>
                        <NavDropdown title={Object.keys(menuprops.menulist)[1]} id="basic-nav-dropdown" className="dropDownTitle" >
                            {menuprops.menulist.Products.map((item, key) =>
                                <LinkContainer key={key} to={item.link}>
                                    <NavDropdown.Item> {item.title} </NavDropdown.Item>
                                </LinkContainer>
                            )}
                        </NavDropdown>
                    {/* <NavLink to="/download">{Object.keys(menuprops.menulist)[2]}</NavLink> */}
                    <NavLink to="/contact">{Object.keys(menuprops.menulist)[2]}</NavLink>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
}

export default React.memo(Menu);